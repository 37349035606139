@import "../../../styles/app";

.root {
  // styles
}

.selectCompany {
  margin-left: 30px;
  height: 30px;
  margin-bottom: 25px;
}

.searchUser {
  margin-left: 10px;
  height: 30px;
  margin-bottom: 25px;
}

.exportButton {
  width: 100%;
  margin-left: 10px;
  height: 30px;
  margin-bottom: 25px;
}
