@import '../../../styles/app';

.root {
  // styles
}

.selectCompany{
  margin-left: 10px;
  height: 30px;
  margin-bottom: 25px;
}

.searchUser{
  margin-left: 10px;
  height: 30px;
  margin-bottom: 25px;
}